<template>
  <div>
    <b-row>
      <b-col cols="10">
        <h1>System Messages</h1>
      </b-col>
      <b-col cols="3" class="mt-sm-3">
        <skype-bot-selector @botChanged="(bot) => (formItem.botId = bot?.botId)"/>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" xl="8">
        <b-row>
          <b-col cols="12" class="p-3"></b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <system-message
                class="mb-5"
                :botId="formItem.botId"
                :system-messages="systemMessages"
            ></system-message>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div v-if="isBusy" class="spinner-busy">
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading...</strong>
    </div>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
import SystemMessage from '@/components/Intents/SystemMessage';
import SkypeBotSelector from '../../components/SkypeBots/SkypeBotSelector.vue';

export default {
  mixins: [httpclient],
  components: {
    SkypeBotSelector,
    SystemMessage,
  },
  mounted() {
    const botId = this.$store.state.selectedSkypeBot?.botId;
    if (botId) this.formItem.botId = botId;
  },
  data() {
    return {
      path: 'system-messages',
      bots: [],
      formItem: {
        botId: -1,
      },
      selectedSystemMessage: {
        name: 'systemmessage',
        title: 'System Message',
        type: 'answer',
      },
      systemMessages: [
        {name: 'noanswer', title: 'No Answer', type: 'text'},
      ],
    };
  },
  watch: {},
  methods: {
    getSystemMessage(data) {
      this.selectedSystemMessage = data;
      this.modalSystemMessage = true;
    },
  },
};
</script>
